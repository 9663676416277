import React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
    width: 10rem;
    height: 10rem;
    margin: -3rem auto;
`;
const SunSVG: React.FC = () => (
    <>
        <StyledSvg xmlns="http://www.w3.org/2000/svg" width="83.051" height="29.07" viewBox="0 0 83.051 29.07">
  <g id="Group_1" data-name="Group 1" transform="translate(-1152.974 -574.766)">
    <line id="Line_3" data-name="Line 3" y2="15" transform="translate(1194 577)" fill="none" stroke="#707070" stroke-width="1"/>
    <line id="Line_4" data-name="Line 4" x1="9" y2="17" transform="translate(1199 575)" fill="none" stroke="#707070" stroke-width="1"/>
    <line id="Line_5" data-name="Line 5" x2="7" y2="17" transform="translate(1182 575)" fill="none" stroke="#707070" stroke-width="1"/>
    <line id="Line_6" data-name="Line 6" x1="9" y2="8" transform="translate(1204 585)" fill="none" stroke="#707070" stroke-width="1"/>
    <line id="Line_7" data-name="Line 7" x2="6" y2="9" transform="translate(1177 584)" fill="none" stroke="#707070" stroke-width="1"/>
    <line id="Line_8" data-name="Line 8" x1="18" y2="10" transform="translate(1208 585)" fill="none" stroke="#707070" stroke-width="1"/>
    <line id="Line_9" data-name="Line 9" x2="13" y2="12" transform="translate(1165 584)" fill="none" stroke="#707070" stroke-width="1"/>
    <line id="Line_11" data-name="Line 11" x2="10" y2="5" transform="translate(1165 595)" fill="none" stroke="#707070" stroke-width="1"/>
    <line id="Line_12" data-name="Line 12" y1="4" x2="10" transform="translate(1213 595)" fill="none" stroke="#707070" stroke-width="1"/>
    <line id="Line_13" data-name="Line 13" x1="20" y2="1" transform="translate(1216 602)" fill="none" stroke="#707070" stroke-width="1"/>
    <line id="Line_14" data-name="Line 14" x1="19" y1="1" transform="translate(1153 602)" fill="none" stroke="#707070" stroke-width="1"/>
    <path id="Path_1" data-name="Path 1" d="M1174.5,603.639c19.959-22.007,39,0,39,0" transform="translate(0 -0.139)" fill="none" stroke="#707070" stroke-width="1"/>
  </g>
        </StyledSvg>
    </>
);

export default SunSVG;
