import Interweave, { Markup } from "interweave";
import { EmailMatcher, UrlMatcher } from 'interweave-autolink';
import React from "react";
import { gatsbyLinkTransform } from '../../utils/interweave-utils';
import LocalizedLink from "../LocalizedLink";
import styled from 'styled-components';
import { LocalizedLinkProps } from '../LocalizedLink';

//#region AdmissionOpenHeading
export const PinkButton = styled.button`
  color: #f8f8f8;
  background-color: #900E4B;
  width: 100%;
  height: 3rem;
  padding: 0 1rem;
  border-radius: 10px;
  font-family: 'Roboto';
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 1.2rem;
  cursor: pointer;
`;
//#endregion

//#region AdmissionOpenHeading
export const WhiteButton = styled(PinkButton)`
  color: #1d1010;
  background-color: white;
`;
//#endregion