import { graphql, Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { useStringsContext } from "../../contexts/strings-context";
import { SEO } from "../../components/SEO";
import { PageProps } from "../../types/global";
import { Fade, AttentionSeeker, Zoom } from "react-awesome-reveal";
import { Card } from "../../components/card-element/card";
import { IoIosPeople } from "react-icons/io";
import AdmissionOpenSVG from "../../assets/svg/admissionOpen";
import { AdmissionOpenHeading, AdmissionOpenBody, AdmissionOpenHeadingTop, AboutUsBody } from "../../components/text/typography";
import DreamSVG from "../../assets/svg/dream";
import { PinkButton, WhiteButton } from "../../components/button/button";
import LocalizedLink from "../../components/LocalizedLink";
import AboutUsSVG from "../../assets/svg/aboutUsSVG";
import { StaticImage } from "gatsby-plugin-image";
import SunSVG from "~/assets/svg/sunSvg";

export const query = graphql`
    query($language: String) {
        strings(language: { eq: $language }) {
            ...LayoutStringsFragment
            varqaschool
          }
    }
`;

const IoPeople = styled(IoIosPeople)`
    align-self: center;
    width: 7rem;
    height: 7rem;
    color: rgba(255,255,255,0.2);
    margin-bottom: -2rem;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: nowrap;
    min-width: 100%;
    margin: 10rem 0;
    @media(max-width: 1300px) {
      flex-direction: column-reverse;
      margin-bottom: 3rem;
    }
`;

const ContainerAboutus = styled(Container)`
    margin: 10rem 0;
    min-height: unset;
`;

const TextContainer = styled.div`
  display:  flex;
  flex-flow: column nowrap;
`;
const QuoteContainer = styled.div`
  display:  flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin: 5rem 0;
  p {
    color: whitesmoke;
    font-size: 1.3rem;
    letter-spacing: 2px;
    line-height: 2rem;
    width: 60%;
    margin: 0 auto;
    padding-bottom: 0.7rem;
  }
`;
const Link1 = styled(LocalizedLink)`
  
`;
const ButtonContainer = styled.div`
  display:  flex;
  flex-flow: row wrap;
  padding: 2rem 0;
  justify-content: center;
  gap: 1rem;
`;
const Image = styled.div`
  width: 2rem;
  height: 2rem;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  a {
    line-height: 2rem;
    text-align: center;
    font-size: 1.2rem;
    color: #dfdfdf;
    text-decoration: none;
  }
`;

const IndexPage: React.FunctionComponent<PageProps> = (props) => {
  const strings = useStringsContext();
  return (
    <>
      <SEO
        lang={props.pageContext.language}
        title="Varqa School - Home"
        description="Varqa School &amp; Junior College is one of the most prestigious schools in Ahmednagar District, having been around for more than 40 years. The school is in an area that is rich in greenery and is surrounded by an extremely healthy and open environment. Our school is also partnered with Cambridge University to provide our students with more rich content, and the school is committed to maintaining highly-trained and innovative faculty."
        keywords={["varqa", "school", "varqa school", "varka", "vaka", "varkha", "varqa school", "college", "ahmenagar", "ahmednagar", "maharashtra", "best school", "schools near me", "schol", "schoole", "schools", "varqa", "best", "top schools", "cbse", "cbse school", "state board", "state", "english medium", "school medium", "english school", "english", "ramin", "ruhiya", "saba", "sir", "madam", "ahmednagr best school", "nagar best school", "nagar", "school in nagar", "nagar school"]}
        url={props.location.pathname}
      />
      <QuoteContainer>
        <SunSVG/>
        <p>"...attach importance to the training of the children, for whatever they learn in that early stage of their development will leave its traces upon their whole life. It becomes part of their nature."</p>
        <InnerContainer>
          <a href="https://www.bahai.org" target="_blank">- Bahá’í Writing</a>
          <Image>
          <StaticImage src="../../assets/png/jasmine.png" alt="jasmine flower" />
        </Image>      
        </InnerContainer>
      </QuoteContainer>
      <Container>
        <TextContainer>
          <AdmissionOpenHeadingTop>Admission Open</AdmissionOpenHeadingTop>
          <AdmissionOpenHeading>For the year 2022-2023</AdmissionOpenHeading>
            <DreamSVG />
            <AdmissionOpenBody>YOUR CHILD'S</AdmissionOpenBody>
            <AdmissionOpenBody style={{fontSize:"2rem"}}>DREAM</AdmissionOpenBody>
            <AdmissionOpenBody>STARTS HERE...</AdmissionOpenBody>
            <ButtonContainer>
              <Link1 href="https://forms.gle/LmerSvGpxfAWgrBfA" target="_blank"><PinkButton>APPLY NOW</PinkButton></Link1>
              <Link to="/enquire"><WhiteButton>ENQUIRE NOW</WhiteButton></Link>
            </ButtonContainer>
              <Link1 href="https://wa.me/+919822408237" target="_blank"><PinkButton style={{width:"100%", alignSelf:"center", color:"black", backgroundImage: "linear-gradient(to right top, #51aded, #67b6f2, #7bbff6, #8dc9fb, #9ed2ff)"}}>PLAN YOUR VISIT</PinkButton></Link1>
        </TextContainer>
        <Zoom><AdmissionOpenSVG/></Zoom>
      </Container>
      <ContainerAboutus>
      <Zoom><AboutUsSVG/></Zoom>
        <TextContainer>
        <IoPeople/>
          <AdmissionOpenHeadingTop>About Us </AdmissionOpenHeadingTop>
            <AboutUsBody>Varqa School &amp; Junior College is one of the most prestigious schools in Ahmednagar District, having been around for more than 40 years. The school is in an area that is rich in greenery and is surrounded by an extremely healthy and open environment. Our school is also partnered with Cambridge University to provide our students with more rich content, and the school is committed to maintaining highly-trained and innovative faculty.</AboutUsBody>
            <ButtonContainer>
              <Link to="/about"><WhiteButton style={{width:"100%", color: "#900E4B"}}>Know more about us</WhiteButton></Link>
            </ButtonContainer>
        </TextContainer>
      </ContainerAboutus>
    </>
  )
}

export default IndexPage
